<template>
  <v-app>
<!--    <v-app-bar app>-->
<!--      <v-toolbar-title class="headline text-uppercase">-->
<!--        <span style="color: #009fe3;">SEEJC | SUM</span>-->
<!--      </v-toolbar-title>-->
<!--      <v-spacer></v-spacer>-->
<!--      <v-btn text href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" color="#009fe3">-->
<!--        <v-icon small left>fas fa-globe-europe</v-icon>-->
<!--        <span class="mr-2">HRV</span>-->
<!--      </v-btn>-->
<!--      <v-btn text href="https://github.com/vuetifyjs/vuetify/releases/latest" target="_blank" color="#009fe3">-->
<!--        <v-icon small left>fas fa-globe-europe</v-icon>-->
<!--        <span class="mr-2">ENG</span>-->
<!--      </v-btn>-->
<!--    </v-app-bar>-->

    <v-content style="background-color: white">
      <HelloWorld />
    </v-content>
    <v-footer dark color="#084776" height="50">
      <v-layout
          row
          color="#084776"
          class="pt-1 pb-1white--text text-center"
          align-center
          justify-center
      >
        <p class="footer-title" style="font-size: 15px; margin-top: auto; margin-bottom:auto;">
          Copyright ©
          <strong>
            <a class="footer-link" href="https://sumit.sum.ba/" target="_blank">SUMIT</a>
          </strong> 2020
        </p>
      </v-layout>
    </v-footer>
  </v-app>
</template>

<script>
import HelloWorld from "./components/HelloWorld";

export default {
  name: "App",
  components: {
    HelloWorld
  },
  created() {
    localStorage.setItem("language_most", 'hr')
  },
  data: () => ({
    //
  })
};
</script>