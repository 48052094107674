<template>
  <div id="topOfApp">
    <v-app-bar app height="80" v-scroll="onScroll">
      <v-toolbar-title class="headline text-uppercase">
        <a v-scroll-to="'#topOfApp'" class="hidden-sm-and-down">
          <span style="color: #009fe3;">Mostariensia | SUM</span>
        </a>
        <a v-scroll-to="'#topOfApp'" class="hidden-md-and-up">
          <img style="width: 90px" :src="require('@/assets/img/most_logo.png')"/>
        </a>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-app-bar-nav-icon class="hidden-md-and-up" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-items class="hidden-sm-and-down">
        <div
            v-for="(item, i) in $t('items')"
            :key="i"
            style="height: 0px; margin-top: 27px; box-shadow: 0px 0px; "
        >
          <a class="nav-act" v-scroll-to="item.link">
            <v-list class="pa-0" style="background-color: transparent">
              <v-list-item class="pl-2 pr-2">
                <v-list-item-title class="body-2">
                  <p style="font-weight: bold; color: #084776" v-html="item.title.toUpperCase()"></p>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </a>
        </div>
      </v-toolbar-items>
            <v-spacer></v-spacer>
            <v-btn text color="#009fe3" @click="changeLocale('hr')">
              <v-icon small left>fas fa-globe-europe</v-icon>
              <span class="mr-2">HRV</span>
            </v-btn>
            <v-btn text color="#009fe3" @click="changeLocale('en')">
              <v-icon small left>fas fa-globe-europe</v-icon>
              <span class="mr-2">ENG</span>
            </v-btn>

      <v-navigation-drawer v-model="drawer" absolute temporary app>
        <v-list-item>
          <v-list-item>
            <img style="width: 90px" class="pt-2 pb-2" :src="require('@/assets/img/most_logo.png')"/>
          </v-list-item>
        </v-list-item>

        <v-divider></v-divider>

        <v-list dense>
          <v-list-item v-for="item in $t('items')" :key="item.title" link v-scroll-to="item.link">
            <v-list-item-icon style="width: 25px">
              <v-icon color="#084776">{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-list-item-content v-on="on">
                  <v-list-item-title style="color:#084776">{{ item.title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-app-bar>

    <div>
      <!-- <v-row no-gutters align="center" justify="center" class="hidden-sm-and-down">
        <v-col cols="12" md="3">
          <v-img :src="require('@/assets/img/seejc_logo.png')"></v-img>
        </v-col>
        <v-col cols="12" md="9">
          <div class="background-gradient">
            <v-img height="600" :src="require('@/assets/img/seejc1.png')" class></v-img>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters align="center" justify="center" class="hidden-md-and-up text-center">
        <v-col cols="12" xs="12">
          <div class>
            <img style="width: 100%" :src="require('@/assets/img/seejc1.png')"/>
          </div>
        </v-col>
        <v-col cols="8" style="margin-top: -100px !important;" align-self="center">
          <v-img  :src="require('@/assets/img/seejc_logo.png')"></v-img>
        </v-col>
      </v-row>-->
      <v-img style="width: 100%; height: 600px" :src="require('@/assets/img/most1.png')">
        <v-container>
          <v-row>
            <v-col cols="12" class="text-center">
              <img
                  style="width: 250px; margin-top: 200px"
                  class="pt-2 pb-2"
                  :src="require('@/assets/img/most_logo.png')"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-img>
    </div>
    <v-container id="opceInformacije">
      <v-row>
        <v-col cols="12" md="12" class="text-center">
          <h2 style="color: #009fe3">{{ $t('about') }}</h2>
          <!-- <p>
            Poštovani,
            pred vama se nalazi znanstveni časopis South Eastern European Journal of Communication čiji je
            pokretač Studij odnosa s javnošću Filozofskog fakulteta Sveučilišta u Mostaru, osnovan 2014. kao prvi
            takve vrste na javnim sveučilištima u jugoistočnoj Europi. Godine 2016., studij je pokrenuo godišnju
            znanstveno-stručnu konferenciju PR Days Mostariensis, koja je stasala i stekla reputaciju mjesta gdje
            se jednom godišnje okupljaju vodeći autori iz područja odnosa s javnošću, integrirane komunikacije i
            medija iz jugoistočne Europe.
            Radovi s prve dvije konferencije objavljeni su u drugim časopisima kojima je izdavač Filozofski
            fakultet.
          </p>
          <p>
            Smatrajući da se time zaokružuje cjelina (sva tri ciklusa studija, znanstvena konferencija, znanstveni
            časopis), nakon treće konferencije pojavila se potreba za pokretanjem interdisciplinarnoga
            znanstvenog časopisa iz područja društvenih znanosti South Eastern European Journal of
            Communication.
          </p>-->
          <p>{{ $t('about_text') }}</p>
          <p>{{ $t('about_text1') }}</p>

          <div v-html="$t('about_text2')"></div>
        </v-col>
        <v-col id="urednistvo" cols="12" md="12" class="text-center">
          <h2 style="color: #009fe3">{{ $t('editional_board') }}</h2>
        </v-col>
        <v-col cols="12" md="4" xs="12" class="text-center">
          <v-btn fab color="#009fe3" x-large dark>
            <v-icon>fas fa-user</v-icon>
          </v-btn>
          <h3 class="mt-3" style="color: #085089">{{ $t('editor') }}</h3>
          <p>{{ $t('zoran_tomic') }}</p>
        </v-col>
        <v-col cols="12" md="4" xs="12" class="text-center">
          <v-btn fab color="#009fe3" x-large dark>
            <v-icon>fas fa-user</v-icon>
          </v-btn>
          <h3 class="mt-3" style="color: #085089">{{ $t('editors') }}</h3>
          <p>{{ $t('zeljko_holjevac') }}</p>
        </v-col>
        <v-col cols="12" md="4" xs="12" class="text-center">
          <v-btn fab color="#009fe3" x-large dark>
            <v-icon>fas fa-user</v-icon>
          </v-btn>
          <h3 class="mt-3" style="color: #085089">{{ $t('secretary') }}</h3>
          <p>{{ $t('ivica_music') }}</p>
        </v-col>
      </v-row>

      <!-- <v-row >
        <v-col cols="12" md="12" class="text-center">
          <h2 style="color: #009fe3">Najnoviji članci</h2>
          <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </v-col>
      </v-row>-->
    </v-container>
    <div>
      <v-img
          height="500"
          class="hidden-sm-and-down"
          :src="require('@/assets/img/seejc_vizual1.jpg')"
      ></v-img>
      <v-img height="200" class="hidden-md-and-up" :src="require('@/assets/img/seejc_vizual1.jpg')"></v-img>
    </div>

    <v-container id="clanci">
      <v-row align="center" justify="center">
        <v-col cols="12" md="12" class="text-center">
          <h2 style="color: #085089">{{ $t('archive') }}</h2>
          <p></p>
        </v-col>
        <v-col cols="12" md="4" xs="12" v-for="(item, i) in items" :key="i">
          <v-card shaped elevation="6">
            <h3 class="pr-4 pl-4 pt-4" style="color: #085089">{{ item.title }}</h3>
            <v-card-subtitle>{{ item.subtitle }}</v-card-subtitle>
            <!-- <v-card-text>Tekst članka, tekst članka, tekst članka, tekst članka, tekst članka, tekst članka, tekst članka.</v-card-text> -->

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-dialog :show.sync="dialog" width="500" :active.sync="i">
                <template v-slot:activator="{ on }">
                  <v-btn
                      color="#085089"
                      outlined
                      class="mr-5"
                      width="100"
                      v-on="on"
                      @click="getNovost(item.alias)"
                  >više
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline grey lighten-2" primary-title>
                    {{ item.title + " " }}{{ item.subtitle }}
                  </v-card-title>

                  <v-card-text>
                    <ul>
                      <li v-for="(stavka, j) in stavke.documents" :key="j">
                        <a :href="'https://web-admin.sum.ba/api/storage/' + stavka.filename"
                           target="_blank">{{ stavka.title }}</a>
                      </li>
                    </ul>
                    <!-- {{stavke}} -->
                  </v-card-text>

                  <v-divider></v-divider>
                </v-card>
              </v-dialog>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container id="upute" class>
      <v-row>
        <v-col cols="12" class="text-center pl-4 pr-4">
          <h2 style="color: #085089">{{ $t('instruction') }}</h2>
          <v-row>
            <v-col cols="12" md="3">
              <v-btn outlined color="#084776"
                     :href="$i18n.locale === 'hr' ? 'https://web-admin.sum.ba/api/storage/Upute_autorima_1680263945_95.pdf' : 'https://web-admin.sum.ba/api/storage/Instructions_for_authors_1702976371_95.pdf'" target="_blank">
                {{ $t('instructionsForAuthors')}}
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn outlined color="orange"
                     :href="$i18n.locale === 'hr' ? 'https://web-admin.sum.ba/api/storage/Upute_recenzentima_1680263945_62.pdf' : 'https://web-admin.sum.ba/api/storage/Instructions_for_Reviewers_1708094923_39.pdf'" target="_blank">
                {{ $t('instructionsForReviewers') }}
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn outlined color="#000616"
                     :href="$i18n.locale === 'hr' ? 'https://web-admin.sum.ba/api/storage/Upute_reference_1680263945_10.pdf' : 'https://web-admin.sum.ba/api/storage/Instructions_for_references_1702976371_91.pdf'" target="_blank">
                {{$t('instructionsForReferences')}}
              </v-btn>
            </v-col>
            <v-col cols="12" md="3">
              <v-btn outlined color="purple"
                     :href="$i18n.locale === 'hr' ? 'https://web-admin.sum.ba/api/storage/Etika_istra%C5%BEivanja_1680263945_62.pdf' : 'https://web-admin.sum.ba/api/storage/Research_ethics_1702976371_85.pdf'"
                     target="_blank">{{ $t('researchEthics') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container id="urednici">
      <v-row class="text-center" align="center" justify="center">
        <v-col cols="12" md="12" class="text-center">
          <h2 style="color: #085089">{{ $t('editorial') }}</h2>
          <p></p>
        </v-col>
        <v-col cols="12" md="3" xs="12" v-for="i in $t('urednicko_vijece')" :key="i">
          <v-card elevation="5" height="100%" class="mt-6">
            <v-row>
              <!-- <v-col cols="12" md="4" xs="4" class="text-center">
                <v-avatar size="75">
                  <v-img :src="require('@/assets/img/user.png')" class></v-img>
                </v-avatar>
              </v-col>-->
              <v-col cols="12" md="12" xs="12" class="text-center">
                <v-card-text class="subtitle" style="color: #085089">{{ i }}</v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container id="medunarodno" class="mt-10">
      <v-row class="text-center" align="center" justify="center">
        <v-col cols="12" md="12" class="text-center">
          <h2 style="color: #085089">{{ $t('internacional') }}</h2>
        </v-col>
        <v-col cols="12" md="4" xs="12" v-for="i in $t('medunarodno_vijece')" :key="i">
          <v-card elevation="5" height="100%" class="mt-4">
            <v-row>
              <!-- <v-col cols="12" md="4" xs="4" class="text-center">
                <v-avatar size="75">
                  <v-img :src="require('@/assets/img/user.png')" class></v-img>
                </v-avatar>
              </v-col>-->
              <v-col cols="12" md="12" xs="12" class="text-center">
                <v-card-text style="color: #085089">{{ i }}</v-card-text>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
        <v-col cols="12"></v-col>
        <v-col cols="12" md="3" class="text-center mt-10">
          <v-btn fab color="#009fe3" x-large dark>
            <v-icon>fas fa-user</v-icon>
          </v-btn>
          <h3 class="mt-3" style="color: #085089">{{ $t('secretary_2') }}</h3>
          <p>Jelena Jurčić</p>
        </v-col>
        <v-col cols="12" md="3" xs="12" class="text-center">
          <v-btn fab color="#009fe3" x-large dark>
            <v-icon>fas fa-user</v-icon>
          </v-btn>
          <h3 class="mt-3" style="color: #085089">{{ $t('proofreadingCroatianLang') }}</h3>
          <p>Josipa Šunjić</p>
        </v-col>
        <v-col cols="12" md="3" xs="12" class="text-center">
          <v-btn fab color="#009fe3" x-large dark>
            <v-icon>fas fa-user</v-icon>
          </v-btn>
          <h3 class="mt-3" style="color: #085089">{{ $t('proofreadingEnglishLang') }}</h3>
          <p>Ivana Grbavac</p>
        </v-col>
        <v-col cols="12" md="3" xs="12" class="text-center">
          <v-btn fab color="#009fe3" x-large dark>
            <v-icon>fas fa-user-friends</v-icon>
          </v-btn>
          <h3 class="mt-3" style="color: #085089">{{ $t('graphicPreparationPrinting') }}</h3>
          <p>Pressum</p>
        </v-col>
        <!-- <v-col cols="12" md="4" xs="12" v-for="i in 6" :key="i">
          <v-card elevation="6">
            <v-row>
              <v-col cols="12" md="4" xs="4" class="text-center">
                <v-avatar size="75">
                  <v-img :src="require('@/assets/img/user.png')" class></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12" md="8" xs="8">
                <v-card-title style="color: #085089">Ime i prezime</v-card-title>
                <v-card-subtitle>Urednik</v-card-subtitle>
              </v-col>
            </v-row>
          </v-card>
        </v-col>-->
      </v-row>
    </v-container>
    <div style="background: #e6e4e8" id="kontakt">
      <v-container id="kontakt">
        <v-row>
          <v-col cols="12" sm="6" xs="12">
            <h2 class="font pb-4" style="color: #085089">{{ $t('contact') }}</h2>
            <!-- <p class="font" v-html="$t('telephone')"></p> -->
            <p class="font">
              <v-icon class="pr-2" color="#085089" small>far fa-envelope</v-icon>
              <span style="color: grey">mostariensia@sum.ba</span>
            </p>
            <p class="font">
              <v-icon class="pr-2" color="#085089" small>fas fa-phone</v-icon>
              <span style="color: grey">+387 36 310778</span>
            </p>
            <p class="font">
              <v-icon class="pr-2" color="#085089" small>fas fa-fax</v-icon>
              <span style="color: grey">+387 36 320885</span>
            </p>
            <p class="font">
              <v-icon class="pr-2" color="#085089" small>fas fa-map-marker-alt</v-icon>
              <span
                  style="color: grey"
              >{{ $t('sum')}}</span>
            </p>
            <p class="font">
              <v-icon class="pr-2" color="#085089" small>fas fa-globe</v-icon>
              <span style="color: grey">
                <a style="color: grey; text-decoration: underline">https://most.sum.ba</a>
                <!-- <a style="color: grey; text-decoration: underline">https://prdays.sum.ba</a> -->
              </span>
            </p>
          </v-col>
          <!-- <v-col cols="12" sm="6" xs="12">
            <h2
              class="font pb-6 text-center"
              style="color: #085089"
            >Pronađite nas na društvenim mrežama</h2>
            <div class="text-center">
              <v-btn fab color="#3B5998" dark href="#" target="_blank">
                <v-icon>fab fa-facebook-f</v-icon>
              </v-btn>
              <v-btn fab color="#1DA1F2" dark class="ml-3" href="#" target="_blank">
                <v-icon>fab fa-twitter</v-icon>
              </v-btn>
              <v-btn fab color="#FF0000" dark class="ml-3" href="#" target="_blank">
                <v-icon>fab fa-youtube</v-icon>
              </v-btn>
              <v-btn fab color="#F56040" dark class="ml-3" href="#" target="_blank">
                <v-icon>fab fa-instagram</v-icon>
              </v-btn>
              <v-btn fab color="#0077B5" dark class="ml-3" href="#" target="_blank">
                <v-icon>fab fa-linkedin-in</v-icon>
              </v-btn>
            </div>
          </v-col>-->
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import i18n from "./../plugins/i18n";
import axios from "axios";
import * as assert from "assert";

export default {
  computed: {
    assert() {
      return assert
    }
  },
  created() {

    this.getNovosti()
  },
  mounted() {

  },
  methods: {
    onScroll() {
      this.isScrolling =
          (window.pageYOffset || document.documentElement.scrollTop || 0) > 100;
    },
    changeLocale(locale) {
      localStorage.setItem("language_most", locale);
      this.getNovosti()
      this.languageActive = false;
      i18n.locale = locale;
      this.lang = locale;
    },

    getNovost(alias) {
      let requestBody = {
        alias: alias
      };

      axios
          .post("https://web-admin.sum.ba/api/web/objava", requestBody)
          .then(response => {
            this.stavke = response.data;
            // for (let i = 0; i < this.stavke.images.length; i++) {
            //   this.lista_slika.push(
            //     "https://web-admin.sum.ba/api/storage/" + this.stavke.images[i]
            //   );
            // }
          })
          .catch(error => console.log(error.message));
    },
    getNovosti() {
      let requestBody = {
        page: this.page,
        postsPerPage: 50,
        categories: ["Mostariensia"]
      };

      axios
          .post("https://web-admin.sum.ba/api/web/objave", requestBody)
          .then(response => {
            this.items = response.data;
          })
          .catch(error => console.log(error.message));
    },

    modalActiveContent: function (i) {
      return this.active === i;
    },

    modalOpen: function (i) {
      this.showModal = true;
      return (this.active = i);
    },
    setModalClose: function () {
      this.show = false;
      //if need set active content to zero object
      // this.active = 0;
    }
  },
  data: () => ({
    show: false,
    active: 0,
    showModal: false,
    dialog: false,
    items: [],
    stavke: [],
    pagination_num: 0,
    page: 1,
    drawer: null,
    languageActive: true,
    lang: "hr",

    medunarodno_vijece: [
      "dr. sc. Mladen Ančić, red. prof. (University of Zadar)",
      "dr. sc. Stjepan Damjanović, red. prof. (University of Zagreb)",
      "dr. sc. Jadranka Gvozdanović, red. prof. (University of Heidelberg)",
      "dr. sc. Nevenko Herceg, red. prof. (University of Mostar)",
      "dr. sc. Georg Holzer, izv. prof. (University of Vienna)",
      "dr. sc. Zdenko Klepić, red. prof. (University of Mostar)",
      "dr. sc. Stjepan Krasić, red. prof. (Dubrovnik International University)",
      "dr. sc. Mile Lasić, red. prof. (University of Mostar)",
      "dr. sc. Antun Lučić, red. prof. (University of Mostar)",
      "dr. sc. Nikola Mijatović, red. prof. (University of Zagreb)",
      "dr. sc. Šimun Musa, red. prof. (University of Mostar)",
      "dr. sc. Marko Mustapić, izv. prof. (University of Zagreb)",
      "dr. sc. Zvjezdan Penezić, red. prof. (University of Zadar)",
      "dr. sc. Ivica Šarac, red. prof. (University of Mostar)",
      "dr. sc. Nevio Šetić, red. prof. (University of Pula)",
      "dr. sc. Božo Žepić, red. prof. (University of Mostar)"
    ],
    urednicko_vijece: [
      "dr. sc. Sanja Bijakšić, red. prof. (University of Mostar)",
      "dr. sc. Miljenko Brekalo, red. prof. (Institute of Social Sciences Ivo Pilar Zagreb,Osijek)",
      "dr. sc. Željko Holjevac, red. prof.(Zagreb)",
      "dr. sc. Vesna Kazazić, red. prof. (University of Mostar)",
      "dr. sc. Stipe Kutleša, red. prof. (Institute of Philosophy, Zagreb)",
      "dr. sc. Ante Pavlović, izv. prof. (Theological institute of Mostar)",
      "dr. sc. Damir Primorac, izv. prof. (University of Split)",
      "dr. sc. Željko Šantić, red. prof. (University of Mostar)",
      "dr. sc. Zoran Tomić, red. prof. (University of Mostar)",
      "dr. sc. Mario Vasilj, red. prof. (University of Mostar)",
    ]
  })
};
</script>
<style>
/* .red {
    outline: 300px solid rgba(255, 0, 0, 0.5) !important;
    outline-offset: -300px;
    overflow: hidden;
    position: relative;
    height: 200px;
    width: 100%;
} */
</style>